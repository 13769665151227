import React from 'react';
import PropTypes from 'prop-types';

import injectSheet from 'react-jss';

import DynamicIcon from '@stratumn/icons/lib/DynamicIcon';

import moment from 'moment';

import { getByPath } from 'utils/widgets';

import styles from './date.style';

const DEFAULT_DISPLAY_FORMAT = 'DD/MM/YYYY';

const DateViewImpl = React.memo(({ classes, view, data }) => {
  const {
    path,
    inputFormat,
    format = DEFAULT_DISPLAY_FORMAT,
    isDeadline,
    deadlineWarningBuffer = 0,
    donePath,
    deadlineWarningIcon
  } = view;

  const dateStr = getByPath(data, path);
  if (!dateStr) return null;

  const date = moment.utc(dateStr, inputFormat).local();

  // Deadline functionalities
  let warning = false;
  let daysToDeadline = null;
  let done = null;
  let countdownStr = 'Done';
  if (isDeadline) {
    daysToDeadline = date.diff(moment(), 'days');
    const daysToWarning = daysToDeadline - deadlineWarningBuffer;
    done = getByPath(data, donePath);
    warning = !done && daysToWarning <= 0;
    if (!done) {
      switch (true) {
        case daysToDeadline === 0:
          countdownStr = 'Due today';
          break;
        case daysToDeadline > 0:
          countdownStr = `Due in ${daysToDeadline} days`;
          break;
        default:
          countdownStr = `${-daysToDeadline} days overdue`;
      }
    }
  }

  const renderWarningIcon = () => {
    if (!deadlineWarningIcon)
      return (
        <svg
          width="15"
          height="15"
          viewBox="0 0 16 16"
          role="img"
          focusable="false"
          aria-hidden="true"
          fill="currentColor"
          className={classes.defaultDeadlineIcon}
        >
          <path d="M11 1C13.2091 1 15 2.79086 15 5V11C15 13.2091 13.2091 15 11 15H5C2.79086 15 1 13.2091 1 11V5C1 2.79086 2.79086 1 5 1H11ZM13.5 6H2.5V11C2.5 12.3807 3.61929 13.5 5 13.5H11C12.3807 13.5 13.5 12.3807 13.5 11V6Z" />
        </svg>
      );
    return (
      <DynamicIcon
        icon={deadlineWarningIcon}
        className={classes.dateViewDeadlineIcon}
      />
    );
  };
  return (
    <div className={classes.dateView} title={isDeadline && countdownStr}>
      <div className={classes.dateViewValue} data-warning={warning}>
        {date.format(format)}
      </div>
      {warning && renderWarningIcon()}
    </div>
  );
});
DateViewImpl.propTypes = {
  classes: PropTypes.object.isRequired,
  view: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};

// sort defaults to text on path (text is ok assuming iso dates)
const getSortConfig = view => ({
  type: 'text',
  path: view.path
});

// filtering defaults to text search on path
// but using a date interpreter in case a specific display format has been set
const getFilterConfig = view => ({
  type: 'dateRange',
  path: view.path,
  inputFormat: view.inputFormat,
  format: view.format || DEFAULT_DISPLAY_FORMAT
});

// width defaults to 'medium'
const getDefaultWidth = () => 'medium';

// stringifies data at path
const getStringifyFunction = view => {
  const { path, inputFormat, format = DEFAULT_DISPLAY_FORMAT } = view;

  return data => {
    const dateStr = getByPath(data, path);
    if (!dateStr) return '';

    const date = moment.utc(dateStr, inputFormat).local();

    return date.format(format);
  };
};

export default {
  component: injectSheet(styles)(DateViewImpl),
  getSortConfig,
  getFilterConfig,
  getDefaultWidth,
  getStringifyFunction
};
